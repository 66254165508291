import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "./components/Header";

const Confirmation = () => {
    const { orderId } = useParams();

    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState([]);
    const [organizerId, setOrganizerId] = useState(0);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        requestFormSettings()
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            setOrder(order);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getReceiptOfOrder = async () => {
        await axios
            .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Receipt.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div className="container wrapper">
            <Header exit={false} />
            <div className="row p-5">
                <div className="col-md-6 col-12 mb-4">
                    <p className='fs-4 text-start'>BESTEN DANK FÜR IHRE BESTELLUNG</p>
                    <p className='text-start fs-medium'>Ihre Bestell-Nr. lautet: {order?.invoiceNumber}</p>
                    <p className='text-start fs-medium'>Betrag: {order?.currency} {order?.totalPrice?.toFixed(2)}</p>
                    <p className='text-start fs-medium mb-1'>Bitte notieren Sie Ihre Bestell-Nr. für allfällige Support-Anfragen.</p>
                    <p className='text-start fs-medium'>Sie erhalten in Kürze eine Bestellbestätigung per E-Mail.</p>
                </div>
                <div className="col-md-6 col-12 mb-4 row">
                    <div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <p className="card-text fs-medium">Laden Sie jetzt Ihr Ticket herunter und drucken Sie es aus.</p>
                                <button onClick={getPdfTicketsOfOrder} className="btn button mb-3 col-12">Ticket herunterladen</button>
                                <button onClick={getReceiptOfOrder} className="btn button col-12">Quittung herunterladen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Confirmation