import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { api, getAddressOfOrder, getTicketTypeById, getTicketTypeInfosByTicketTypeId, updateAddress } from 'ticketino-api-client';
import titleOptions from '../data/titleOptions';

const Address = () => {

    const [data, setData] = useState({
        salutation: "",
        firstName: "",
        name: "",
        title: "",
        company: "",
        option2: "",
        function: "",
        street: "",
        postCode: "",
        city: "",
        country: "",
        mobile: "",
        email: ""
    });

    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [countries, setCountries] = useState([]);

    const [error, setError] = useState("");

    const { language } = useParams();

    const [searchParams] = useSearchParams();
    const editMode = searchParams.get("editMode");

    let navigate = useNavigate();

    useEffect(() => {
        requestResources();
    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;


            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    setData({
                        ...data,
                        salutation: ticket.salutation,
                        firstName: ticket.firstname,
                        name: ticket.lastname,
                        title: ticket.title,
                        company: ticket.company,
                        option1: ticket.option1,
                        option2: ticket.option2,
                        option3: ticket.option3,
                        option4: ticket.option4,
                        option5: ticket.option5,
                        option6: ticket.option6,
                        option7: ticket.option7,
                        function: ticket.function,
                        street: ticket.street,
                        postCode: ticket.postCode,
                        city: ticket.city,
                        country: ticket.country,
                        mobile: ticket.mobile,
                        email: ticket.email
                    })

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            let countries = await requestCountries();

            //first add switzerland, germany and austria
            const indexCH = countries.findIndex((c) => c.id === 176);
            const ch = countries[indexCH];
            countries.splice(indexCH, 1);

            var indexDE = countries.findIndex((c) => c.id === 229);
            const de = countries[indexDE];
            countries.splice(indexDE, 1);

            var indexAU = countries.findIndex((c) => c.id === 228);
            const au = countries[indexAU];
            countries.splice(indexAU, 1);

            countries.unshift({ id: "", name: "---------" });
            countries.unshift(au);
            countries.unshift(de);
            countries.unshift(ch);

            setCountries(countries);

            setOrder(order);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    console.log(data);

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async () => {

        let addressBody = { ...data };

        addressBody.countryId = countries.find((country) => country.name == data.country).id;

        await Promise.all(tickets.map(async (ticket) => {
            await axios.put(`${baseUrl}/Ticket/${ticket.id}/Address`, addressBody)
                .catch((error) => {
                    setError(error.response.data);
                    console.error(error.response.data);
                });
        }))

        if (editMode != true) {
            addressBody.deliveryActive = true;
            addressBody.deliveryFirstName = addressBody.firstName;
            addressBody.deliveryLastName = addressBody.name;
            addressBody.deliveryCompanyName = addressBody.company;
            addressBody.deliveryStreet = addressBody.street;
            addressBody.deliveryPostCode = addressBody.postCode;
            addressBody.deliveryCity = addressBody.city;
            addressBody.deliveryCountryId = addressBody.countryId;
        }

        // update the order address
        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .catch((error) => {
                setError(error.response.data);
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addAddressToBasket();
        navigate(`/${language}/invoiceaddress`);
    }

    const onInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const requestCountries = async () => {
        try {
            const response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
            return response.data;

        } catch (error) {
            console.error(error.response.data);
        }
    };

    let validation = /^.{1,70}$/.test(data.salutation) &&
        /^.{1,70}$/.test(data.firstName) &&
        /^.{1,70}$/.test(data.name) &&
        /^.{1,70}$/.test(data.company) &&
        /^.{1,70}$/.test(data.function) &&
        /^.{1,70}$/.test(data.postCode) &&
        /^.{1,70}$/.test(data.city) &&
        /^.{1,70}$/.test(data.countryId) &&
        /^.{1,70}$/.test(data.telephone) &&
        /^\S+@\S+\.\S+$/.test(data.email);

    return (
        <div className="container wrapper">
            <Header exit={true} progress={25} />
            <div className="row p-5">
                <p className='fs-3 fw-bold'>Allgemeine Daten</p>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Allgemeine Daten
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Anrede <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <select className="form-select" name='salutation' value={data.salutation} onChange={onInputChange}>
                                        <option value="">---</option>
                                        <option value="Herr">Herr</option>
                                        <option value="Frau">Frau</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Titel</label>
                                <div className="col-md-9 col-12">
                                    <select className="form-select" name='title' value={data.title} onChange={onInputChange}>
                                        {titleOptions.map((to, index) => (
                                            <option key={index} value={to}>{to}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Vorname <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="firstName" value={data.firstName} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Nachname <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="name" value={data.name} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Spital / Firma <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="company" value={data.company} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Abteilung / Department</label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="option2" value={data.option2} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Job Titel / Funktion <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="function" value={data.function} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Strasse <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="street" value={data.street} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">PLZ <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="postCode" value={data.postCode} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Stadt <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="city" value={data.city} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Land <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <select className="form-select" name='country' value={data.country} onChange={onInputChange}>
                                        <option value="">---</option>
                                        {countries.length > 0 && countries.map((country, index) => (
                                            <option key={index} value={country?.name}>{country?.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Telefon <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="mobile" value={data.mobile} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Email<span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="email" value={data.email} onChange={onInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Teilnahmegebühr
                        </div>
                        <div className="card-body">
                            <div className="row mb-2">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Teilnahmegebühr</label>
                                <div className="col-md-9 col-12">
                                    <select className="form-select" name='participationfee' value={data.participationfee} onChange={onInputChange}>
                                        {tickets.map((ticket, index) => (
                                            <option key={index} value="participationfee">Teilnahmebegebühr ({ticket.price.toFixed(2)} CHF)</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {error != "" && <div className="mb-4 alert alert-danger" role="alert">
                    {error}
                </div>}
                <div className='row'>
                    <div className="col-6 text-start"><button className='btn button' onClick={() => navigate(`/${language}/event`)}>Zurück</button></div>
                    <div className="col-6 text-end"><button className='btn button' disabled={!validation} onClick={onSubmit}>Weiter</button></div>
                </div>
            </div>
        </div>
    )
}

export default Address