import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { useNavigate, useParams } from 'react-router-dom'

const Home = () => {

    let navigate = useNavigate();

    let { language } = useParams();

    return (
        <div className="container wrapper">
            <Header />
            <div className="row p-5">
                <div className="col-md-6 col-12 mb-4">
                    <p className='fs-4 text-start'>Herzlich Wilkommen</p>
                    <p className='text-start fs-medium'>Wir freuen uns über Ihr Interesse am GU Cancer Forum 2024. Bitte registrieren Sie sich direkt nebenstehend über "Teilnehmen".</p>
                    <p className='text-start fs-medium'>Ihr MediCongress Team  </p>
                </div>
                <div className="col-md-6 col-12 mb-4">
                    <div className="card">
                        <div className="card-header fs-6">
                            NEU-Registrierung
                        </div>
                        <div className="card-body">
                            <p className="card-text fs-medium">Wenn Sie sich für die Veranstaltung anmelden möchten, registrieren Sie sich bitte hier.</p>
                            <button onClick={() => navigate(`/${language}/event`)} className="btn button">Teilnehmen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home