import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { api, getAddressOfOrder } from 'ticketino-api-client';

const InvoiceAddress = () => {
    const [data, setData] = useState({});
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [countries, setCountries] = useState([]);

    const [error, setError] = useState("");

    const { language } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        requestResources();
    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            const address = await getAddressOfOrder(orderId);
            let countries = await requestCountries();

            //first add switzerland, germany and austria
            const indexCH = countries.findIndex((c) => c.id === 176);
            const ch = countries[indexCH];
            countries.splice(indexCH, 1);

            var indexDE = countries.findIndex((c) => c.id === 229);
            const de = countries[indexDE];
            countries.splice(indexDE, 1);

            var indexAU = countries.findIndex((c) => c.id === 228);
            const au = countries[indexAU];
            countries.splice(indexAU, 1);

            countries.unshift({ id: "", name: "---------" });
            countries.unshift(au);
            countries.unshift(de);
            countries.unshift(ch);

            setCountries(countries);

            setData({ ...address, countryId: 176 });
        }
        catch (error) {
            console.error(error);
        }
    }

    const requestCountries = async () => {
        try {
            const response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
            return response.data;

        } catch (error) {
            console.error(error.response.data);
        }
    };

    const addAddressToBasket = async () => {
        let addressBody = { ...data };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => navigate(`/${language}/survey`))
            .catch((error) => {
                setError(error.response.data);
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addAddressToBasket();
    }

    const onInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return (
        <div className="container wrapper">
            <Header exit={true} progress={50} />
            <div className="row p-5">
                <p className='fs-3 fw-bold'>Rechnungsadresse</p>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Rechnungsadresse
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Vorname <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryFirstName" value={data.deliveryFirstName} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Nachname <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryLastName" value={data.deliveryLastName} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Firma <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryCompanyName" value={data.deliveryCompanyName} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Strasse <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryStreet" value={data.deliveryStreet} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">PLZ <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryPostCode" value={data.deliveryPostCode} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Stadt <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="deliveryCity" value={data.deliveryCity} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Land <span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <select className="form-select" name='deliveryCountryId' value={data.deliveryCountryId} onChange={onInputChange}>
                                        {countries.length > 0 && countries.map((country, index) => (
                                            <option key={index} value={country?.id}>{country?.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-md-3 col-12 col-form-label fw-bold mb-2">Email<span className='text-danger'>*</span></label>
                                <div className="col-md-9 col-12">
                                    <input className="form-control" name="email" value={data.email} onChange={onInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {error != "" && <div className="mb-4 alert alert-danger" role="alert">
                    {error}
                </div>}
                <div className='row'>
                    <div className="col-6 text-start"><button className='btn button' onClick={() => navigate(`/${language}/address?editMode=true`)}>Zurück</button></div>
                    <div className="col-6 text-end"><button className='btn button' onClick={onSubmit}>Weiter</button></div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceAddress