import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./views/Home";
import Event from "./views/Step10_Event";
import Address from "./views/Step20_Address";
import InvoiceAddress from "./views/Step30_InvoiceAddress";
import Survey from "./views/Step40_Survey";
import Summary from "./views/Step50_Summary";
import Checkout from "./views/Step60_Checkout";
import Confirmation from "./views/Step70_Confirmation";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/de/home" replace />} />
                <Route path="/:language/home" element={<Home />} />
                <Route path="/:language/event" element={<Event />} />
                <Route path="/:language/address" element={<Address />} />
                <Route path="/:language/invoiceaddress" element={<InvoiceAddress />} />
                <Route path="/:language/survey" element={<Survey />} />
                <Route path="/:language/summary" element={<Summary />} />
                <Route path="/:language/checkout" element={<Checkout />} />
                <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
