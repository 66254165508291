import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { api, getAddressOfOrder, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from 'ticketino-api-client';

const Survey = () => {
    const [data, setData] = useState({});
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [error, setError] = useState("");
    const [tickets, setTickets] = useState([]);

    const [options7, setOptions7] = useState([]);

    const { language } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        requestResources();
    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    setData({
                        ...data,
                        salutation: ticket.salutation,
                        firstName: ticket.firstname,
                        name: ticket.lastname,
                        title: ticket.title,
                        company: ticket.company,
                        option1: ticket.option1,
                        option2: ticket.option2,
                        option3: ticket.option3,
                        option4: ticket.option4,
                        option5: ticket.option5,
                        option6: ticket.option6,
                        option7: ticket.option7,
                        function: ticket.function,
                        street: ticket.street,
                        postCode: ticket.postCode,
                        city: ticket.city,
                        country: ticket.country,
                        mobile: ticket.mobile,
                        email: ticket.email
                    })

                    if (ticket.option7) {
                        setOptions7(ticket.option7.split(";"));                        
                    }


                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async () => {
        let addressBody = { ...data };

        addressBody.option7 = options7.join(";");

        await Promise.all(tickets.map(async (ticket) => {
            await axios.put(`${baseUrl}/Ticket/${ticket.id}/Address`, addressBody)
                .catch((error) => {
                    setError(error.response.data);
                    console.error(error.response.data);
                });
        }))
    };

    const onSubmit = async () => {
        await addAddressToBasket();
        navigate(`/${language}/summary`);
    }

    let validation =
        (data.option3 && /^.{1,70}$/.test(data.option3)) &&
        (data.option4 && /^.{1,70}$/.test(data.option4)) &&
        (data.option6 && /^.{1,70}$/.test(data.option6));

    const onCheckboxChange = (e, value) => {
        setOptions7((prevOptions) => {
            if (e.target.checked) {
                return [...prevOptions, value]
            } else {
                return prevOptions.filter(o => o != value)
            }
        })
    }

    console.log(tickets);

    return (
        <div className="container wrapper">
            <Header exit={true} progress={74} />
            <div className="row p-5">
                <p className='fs-3 fw-bold'>Onlinefragen</p>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Fachgebiet
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-12 col-form-label fw-bold">Welches ist Ihr Fachgebiet? <span className='text-danger'>*</span></label>
                                <div className='col-12'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" checked={data.option3 == "Urologie"} onChange={() => setData({ ...data, option3: "Urologie" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Urologie
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault2" checked={data.option3 == "Medizinische Onkologie"} onChange={() => setData({ ...data, option3: "Medizinische Onkologie" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Medizinische Onkologie
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault3" checked={data.option3 == "Radio-Onkologie"} onChange={() => setData({ ...data, option3: "Radio-Onkologie" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                            Radio-Onkologie
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault4" checked={data.option3 == "Pflege"} onChange={() => setData({ ...data, option3: "Pflege" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                            Pflege
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault5" checked={data.option3 == "andere"} onChange={() => setData({ ...data, option3: "andere" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault5">
                                            andere
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Verpflegung
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-12 col-form-label fw-bold">Ernähren Sie sich vegetarisch? <span className='text-danger'>*</span></label>
                                <div className='col-12'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault6" checked={data.option4 == "Ja"} onChange={() => setData({ ...data, option4: "Ja" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault6">
                                            Ja
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault7" checked={data.option4 == "Nein"} onChange={() => setData({ ...data, option4: "Nein" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault7">
                                            Nein
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label className="col-12 col-form-label fw-bold">Haben Sie Lebensmittelallergien?</label>
                                <div className='col-12'>
                                    <input className='form-control' value={data.option5} onChange={(e) => setData({ ...data, option5: e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Fotofreigabe
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-12 col-form-label fw-bold">Wir weisen Sie darauf hin, dass sowohl Einzel- als auch Gruppenfotos angefertigt werden. Diese Informationen werden wir entweder für Werbung, PR-Massnahmen oder für die Verbreitung von Informationen in Publikationen und/oder im Internet verwenden. Durch das Betätigen des Buttons erklären Sie Ihre Zustimmung zur Nutzung <span className='text-danger'>*</span></label>
                                <div className='col-12'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault8" checked={data.option6 == "Ich stimme zu"} onChange={() => setData({ ...data, option6: "Ich stimme zu" })} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault8">
                                            Ich stimme zu
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(tickets.findIndex((ticket) => ticket.ticketTypeId == 729495) > -1) && <div className='mb-4'>
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-2 row">
                                <label className="col-12 col-form-label fw-bold">Wählen Sie das Standard-Mobiliar? <span className='text-danger'>*</span></label>
                                <div className='col-12'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault1" checked={options7.includes("Tisch")} onChange={(e) => onCheckboxChange(e, "Tisch")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault1">
                                            Tisch
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault2" checked={options7.includes("Hochtisch")} onChange={(e) => onCheckboxChange(e, "Hochtisch")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault2">
                                            Hochtisch
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault3" checked={options7.includes("Stühle")} onChange={(e) => onCheckboxChange(e, "Stühle")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault3">
                                            Stühle
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault4" checked={options7.includes("Stromleiste")} onChange={(e) => onCheckboxChange(e, "Stromleiste")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault4">
                                            Stromleiste
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault5" checked={options7.includes("andere")} onChange={(e) => onCheckboxChange(e, "andere")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault5">
                                            andere
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {error != "" && <div className="mb-4 alert alert-danger" role="alert">
                    {error}
                </div>}
                <div className='row'>
                    <div className="col-6 text-start"><button className='btn button' onClick={() => navigate(`/${language}/invoiceaddress`)}>Zurück</button></div>
                    <div className="col-6 text-end"><button className='btn button' disabled={!validation} onClick={onSubmit}>Weiter</button></div>
                </div>
            </div>
        </div>
    )
}

export default Survey