const titleOptions = [
    "",
    "Ass. Prof.",
    "Dipl. Ing. ETH",
    "Dipl. Ing. HTL",
    "Dipl. TCM Therapeut(in)",
    "Dott.",
    "Dr.",
    "Dr. Clin. Nutr.",
    "Dr. Dr. med.",
    "Dr. h.c.",
    "Dr. iur.",
    "Dr. iur. HSG",
    "Dr. med.",
    "Dr. med. Dipl.-Inf. (FH)",
    "Dr. med. Dipl.-Psych.",
    "Dr. med. Dr. rer. nat.",
    "Dr. med. Dr. sc. nat.",
    "Dr. med. MPH",
    "Dr. med. dent.",
    "Dr. med. univ.",
    "Dr. med. vet.",
    "Dr. med., MBA",
    "Dr. oec.",
    "Dr. oec. HSG",
    "Dr. pharm.",
    "Dr. phil.",
    "Dr. phil. nat.",
    "Dr. rer. pol.",
    "Dr. rer. publ.",
    "Dr. sc. ETH",
    "Dr. sc. nat.",
    "Dr. sc. nat. MHA",
    "Dr. theol.",
    "M.D. PhD",
    "M.D. Priv.-Doz. Dr. med.",
    "M.D. Prof. Dr. ",
    "M.D., MSc",
    "MBA",
    "MD",
    "MD FECTS",
    "MD, Associate Prof",
    "MD, BSc",
    "MD, EMBA",
    "MD, MBA",
    "MD, MBA, PhD",
    "MHA",
    "MPH",
    "MSc",
    "MUDr.",
    "Med. pract.",
    "OA PD Dr. PhD",
    "PD Dr.",
    "PD Dr. med.",
    "PD Dr. med. Dr. phil.",
    "Ph.D.",
    "Prof.",
    "Prof. Dr.",
    "Prof. Dr. Dipl. Psych.",
    "Prof. Dr. Dr.",
    "Prof. Dr. Dr. h.c.",
    "Prof. Dr. Dr. med.",
    "Prof. Dr. med.",
    "Prof. Dr. med. ",
    "Prof. Dr. med. et phil.",
    "Prof. Dr. phil.",
    "Prof. Dr. rer. med.",
    "Prof. em, M.D.",
    "Prof. em.",
    "Prof. em. Dr. med.",
    "Prof. mult. h.c. China Dr.",
    "Prof., MD",
    "Prof., MD, MBA",
    "Prof., MD, PhD",
    "Univ.-Prof.",
    "em. Prof. Dr. med.",
    "lic. iur.",
    "lic. oec. publ.",
    "lic. phil.",
    "lic. rer. pol.",
    "lic. rer. publ. HSG"
]

export default titleOptions;