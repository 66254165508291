import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { api, getAddressOfOrder, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from 'ticketino-api-client';

const Summary = () => {
    const [address, setAddress] = useState({});

    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [countries, setCountries] = useState([]);

    const [agb, setAGB] = useState(false);

    const [deliveryCountry, setDeliveryCountry] = useState("");

    const { language } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;
            const addressOfOrder = await getAddressOfOrder(orderId);

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    setAddress({
                        ...address,
                        salutation: ticket.salutation,
                        firstName: ticket.firstname,
                        name: ticket.lastname,
                        title: ticket.title,
                        company: ticket.company,
                        option1: ticket.option1,
                        option2: ticket.option2,
                        option3: ticket.option3,
                        option4: ticket.option4,
                        option5: ticket.option5,
                        option6: ticket.option6,
                        option7: ticket.option7,
                        function: ticket.function,
                        street: ticket.street,
                        postCode: ticket.postCode,
                        city: ticket.city,
                        country: ticket.country,
                        mobile: ticket.mobile,
                        email: ticket.email,
                        deliveryFirstName: addressOfOrder.deliveryFirstName,
                        deliveryLastName: addressOfOrder.deliveryLastName,
                        deliveryCompanyName: addressOfOrder.deliveryCompanyName,
                        deliveryStreet: addressOfOrder.deliveryStreet,
                        deliveryPostCode: addressOfOrder.deliveryPostCode,
                        deliveryCity: addressOfOrder.deliveryCity
                    })

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            let countries = await requestCountries();
            setDeliveryCountry(countries.find(country => country.id == addressOfOrder.deliveryCountryId)?.name);

            setOrder(order);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const requestCountries = async () => {
        try {
            const response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
            return response.data;

        } catch (error) {
            console.error(error.response.data);
        }
    };

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container wrapper">
            <Header exit={true} progress={99} />
            <div className="row p-5">
                <p className='fs-3 fw-bold'>Übersicht</p>
                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6 text-primary">
                            <div className="row">
                                <div className="col-10 text-start">
                                    Allgemeine Daten
                                </div>
                                <div className="col-2 text-end"><i className="bi bi-pencil-fill" onClick={() => navigate(`/${language}/address?editMode=true`)}></i></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Anrede</p>
                                </div>
                                <div className='col-6'>{address.salutation}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Titel</p>
                                </div>
                                <div className='col-6'>{address.title}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Vorname</p>
                                </div>
                                <div className='col-6'>{address.firstName}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Nachname</p>
                                </div>
                                <div className='col-6'>{address.name}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Spital / Firma</p>
                                </div>
                                <div className='col-6'>{address.company}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Abteilung / Department</p>
                                </div>
                                <div className='col-6'>{address.option2}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Job Titel / Funktion</p>
                                </div>
                                <div className='col-6'>{address.function}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Strasse</p>
                                </div>
                                <div className='col-6'>{address.street}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>PLZ</p>
                                </div>
                                <div className='col-6'>{address.postCode}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Stadt</p>
                                </div>
                                <div className='col-6'>{address.city}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Land</p>
                                </div>
                                <div className='col-6'>{address.country}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Telefon</p>
                                </div>
                                <div className='col-6'>{address.mobile}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Email</p>
                                </div>
                                <div className='col-6'>{address.email}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Teilnahmegebühr</p>
                                </div>
                                <div className='col-6'>{tickets.map((ticket, index) => (
                                    <>Teilnahmebegebühr ({ticket.price.toFixed(2)} CHF)</>
                                ))}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6 text-primary">
                            <div className="row">
                                <div className="col-10 text-start">
                                    Rechnungsadresse
                                </div>
                                <div className="col-2 text-end"><i className="bi bi-pencil-fill" onClick={() => navigate(`/${language}/invoiceaddress`)}></i></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Vorname</p>
                                </div>
                                <div className='col-6'>{address.deliveryFirstName}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Nachname</p>
                                </div>
                                <div className='col-6'>{address.deliveryLastName}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Spital / Firma</p>
                                </div>
                                <div className='col-6'>{address.deliveryCompanyName}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Strasse</p>
                                </div>
                                <div className='col-6'>{address.deliveryStreet}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>PLZ</p>
                                </div>
                                <div className='col-6'>{address.deliveryPostCode}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Stadt</p>
                                </div>
                                <div className='col-6'>{address.deliveryCity}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Land</p>
                                </div>
                                <div className='col-6'>{deliveryCountry}</div>
                            </div>
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <p className='fs-medium'>Email</p>
                                </div>
                                <div className='col-6'>{address.email}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6 text-primary">
                            <div className="row">
                                <div className="col-10 text-start">
                                    Onlinefragen
                                </div>
                                <div className="col-2 text-end"><i className="bi bi-pencil-fill" onClick={() => navigate(`/${language}/survey`)}></i></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <p className='fs-6 fw-bold'>Fachgebiet</p>
                                <div className='col-6'>
                                    <p className='fs-medium'>Welches ist Ihr Fachgebiet?</p>
                                </div>
                                <div className='col-6'>{address.option3}</div>
                            </div>
                            <div className="mb-2 row">
                                <p className='fs-6 fw-bold'>Verpflegung</p>
                                <div className='col-6'>
                                    <p className='fs-medium'>Ernähren Sie sich vegetarisch?</p>
                                </div>
                                <div className='col-6'>{address.option4}</div>
                            </div>
                            <div className="mb-2 row">
                                <p className='fs-6 fw-bold'>Fotofreigabe</p>
                                <div className='col-6'>
                                    <p className='fs-medium'>Wir weisen Sie darauf hin, dass sowohl Einzel- als auch Gruppenfotos angefertigt werden. Diese Informationen werden wir entweder für Werbung, PR-Massnahmen oder für die Verbreitung von Informationen in Publikationen und/oder im Internet verwenden. Durch das Betätigen des Buttons erklären Sie Ihre Zustimmung zur Nutzung</p>
                                </div>
                                <div className='col-6'>{address.option6}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Allgemeine Geschäftsbedingungen AGB
                        </div>
                        <div className="card-body">
                            <div className="mb-2 row">
                                <div className='col-12'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault8" checked={agb} onChange={() => setAGB(!agb)} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault8">
                                            Ja, ich akzeptiere die Allgemeinen Geschäftsbedingungen (AGB) und stimme diesen zu.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-6 text-start"><button className='btn button' onClick={() => navigate(-1)}>Zurück</button></div>
                    <div className="col-6 text-end"><button className='btn button' onClick={() => navigate(`/${language}/checkout`)} disabled={!agb}>Weiter</button></div>
                </div>
            </div>
        </div>
    )
}

export default Summary