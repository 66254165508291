import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { api, getAddressOfOrder, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from 'ticketino-api-client';

import twintlogo from "../images/Twint-Logo.png";
import amexlogo from "../images/American_Express-Logo.wine.png";
import mastercardlogo from "../images/Mastercard-Logo.wine.png";
import visalogo from "../images/Visa_Inc.-Logo.wine.png";

const Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);

    const [error, setError] = useState("");

    const { language } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            setOrder(order);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId);
            })
            .catch((error) => {
                setError(error.response.data);
                console.error(error.response.data);
            });
    };

    const confirmOrder = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            setError(error.response.data);
            console.error(error)
        }
    };

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                setError(error.response.data);
                alert(error.response.data);
            });
    };

    const checkout = async () => {
        let dataTransProviders = [1, 7, 8, 9, 10, 14];

        let datatransProvider = dataTransProviders.findIndex((d) => d === order?.paymentMethodId) !== -1

        if (datatransProvider) {
            startDatatrans();

            const timer = setTimeout(() => {
                window.location.reload();
                sessionStorage.setItem("reloadPageAfterSessionTimeOut", true);
            }, 600000); // 300'000 (5 minutes)

            return () => {
                clearTimeout(timer);
            };
        } else {
            await confirmOrder(orderId);
            navigate(`/${language}/confirmation/${orderId}`);
        }
    }

    const mapTickets = () => {
        return tickets.map((ticket, index) => {

            return (
                <div className='row'>
                    <div className="col-8 fs-6 text-start">
                        Teilnahmegebühr
                    </div>
                    <div className="col-4 text-end">
                        {ticket?.currency} {ticket?.price?.toFixed(2)}
                    </div>
                </div>
            )
        })
    }

    const mapExtraCosts = () => {
        return (
            <div className='row'>
                <div className="col-8 fs-6 text-start">
                    Kosten für Lieferung und Zahlung
                </div>
                <div className="col-4 text-end">
                    {order?.currency + " " + order?.deliverPaymentFee?.toFixed(2)}
                </div>
            </div>
        )
    }

    return (
        <div className="container wrapper">
            <Header exit={false} />
            <div id="datatrans-form-placeholder"></div>
            <div className="row p-5">

                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            <div className='row'>
                                <div className="col-8 fs-6 text-start fw-bold">
                                    Position
                                </div>
                                <div className="col-4 text-end fw-bold">
                                    Preis
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {mapTickets()}
                            {order?.deliverPaymentFee > 0 && mapExtraCosts()}
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className="col-8 fs-6 text-start fw-bold">
                                    zu Bezahlen
                                </div>
                                <div className="col-4 text-end fw-bold">
                                    {order?.currency} {order?.totalPrice?.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-4'>
                    <div className="card">
                        <div className="card-header fs-6">
                            Bezahlart auswählen
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 text-center mb-3">
                                    <div className={'payment' + (order.paymentMethodId == 1 ? "-selected" : "")} onClick={() => changePaymentType(1)}>
                                        <img src={mastercardlogo} className='img img-fluid payment-logo'></img>
                                        <p>Mastercard</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center mb-3">
                                    <div className={'payment' + (order.paymentMethodId == 8 ? "-selected" : "")} onClick={() => changePaymentType(8)}>
                                        <img src={amexlogo} className='img img-fluid payment-logo'></img>
                                        <p>American Express</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center mb-3">
                                    <div className={'payment' + (order.paymentMethodId == 7 ? "-selected" : "")} onClick={() => changePaymentType(7)}>
                                        <img src={visalogo} className='img img-fluid payment-logo'></img>
                                        <p>Visa</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center mb-3">
                                    <div className={'payment' + (order.paymentMethodId == 14 ? "-selected" : "")} onClick={() => changePaymentType(14)}>
                                        <img src={twintlogo} className='img img-fluid payment-twint mt-1'></img>
                                        <p className='mt-2'>Twint</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center mb-3">
                                    <div className={'payment' + (order.paymentMethodId == 2 ? "-selected" : "")} onClick={() => changePaymentType(2)}>
                                        <i className="bi bi-receipt fs-3 fw-bold"></i>
                                        <p>Kauf auf rechnung</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {error != "" && <div className="mb-4 alert alert-danger" role="alert">
                    {error}
                </div>}
                <div className='row'>
                    <div className="col-12 text-end" ><button className='btn button' onClick={checkout}>Weiter</button></div>
                </div>
            </div>
        </div>
    )
}

export default Checkout