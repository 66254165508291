import React from 'react'

import flyer from "../../images/sakk_logo.jpg";
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props) => {

    const { exit, progress } = props;

    const { language } = useParams();
    let navigate = useNavigate();

    return (
        <div>
            <div>
                <img className='text-center flyer img-fluid' src={flyer} alt="expovina flyer"></img>
            </div>
            <hr className='custom-hr'></hr>
            {exit == true && <div className='text-end pe-5 pt-3'>
                <button className='btn button' onClick={() => navigate(`/${language}/home`)}>Abbrechen</button>
            </div>}
            {(progress > 0) && <div className='pt-4 ps-5 pe-5'>
                <div className="progress" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar" style={{ width: `${progress}%` }}>{progress}%</div>
                </div>
            </div>}
        </div>
    )
}

export default Header