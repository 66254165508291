import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets, getLocationInfos, getAddressOfOrder } from "ticketino-api-client";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const sessions = [
    {
        name: "Workshop",
        start: "2024-02-15T13:00:00+01:00"
    },
    {
        name: "Workshop",
        start: "2024-02-15T15:00:00+01:00"
    },
    {
        name: "Workshop",
        start: "2024-02-15T17:00:00+01:00"
    },
    {
        name: "Workshop",
        start: "2024-02-15T19:00:00+01:00"
    },
    {
        name: "Workshop/Session",
        start: "2024-02-16T06:00:00+01:00"
    },
    {
        name: "Workshop/Session",
        start: "2024-02-16T08:00:00+01:00"
    },
    {
        name: "Workshop/Session",
        start: "2024-02-16T10:00:00+01:00"
    },
    {
        name: "Workshop/Session",
        start: "2024-02-16T12:00:00+01:00"
    }
]

const Event = () => {
    const [eventId, setEventId] = useState([]);
    const [event, setEvent] = useState({});
    const [ticketTypes, setTicketTypes] = useState([]);
    const [location, setLocation] = useState({});
    const [selectedTicketType, setSelectedTicketType] = useState(0);
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [orderId, setOrderId] = useState();
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [posId, setPosId] = useState();
    const [error, setError] = useState("");

    const [isClicked, setIsClicked] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (loaded) {
            startOrder(posId, eventId);
        }
    }, [language]); //everytime language is changed

    useEffect(() => {
        if (orderId) {
            loadEvent(eventId, orderId)
        }
    }, [orderId]); //gets called when an order is started

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);

            startOrder(res.data.posId, res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 2,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )


            const location = await getLocationById(event.locationId);
            const locationInfo = await getLocationInfos(event.locationId);

            const updatedLocationInfo = locationInfo.find(info => info.languageId == languageId);

            const updatedLocation = { ...location, info: updatedLocationInfo };

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLocation(updatedLocation);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const getLocationById = async (id) => {
        try {
            const response = await axios.get(`${baseUrl}/Location/${id}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
        }
    }

    const addTicketToBasket = async () => {

        try {
            let addTicketTypes = [];
            addTicketTypes = [{ ticketTypeId: selectedTicketType, quantity: 1 }];
            setLoading(false);

            const response = await axios.post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { ticketsToAdd: addTicketTypes });
            return response.data
        } catch (error) {
            setLoading(false);
            setSelectedTicketType(0);
            setError(error.response.data);
            console.error(error.response.data);
        }
    };

    const addAddressToBasket = async (tickets) => {

        let addressBody = { option1: selectedSessions.map((session) => session.name + " - " + session.start).join("; ") };

        await Promise.all(tickets.map(async (ticket) => {
            await axios.put(`${baseUrl}/Ticket/${ticket.id}/Address`, addressBody)
                .catch((error) => {
                    console.error(error.response.data);
                });
        }))
    };

    const handleDivClick = (name, start) => {
        setSelectedSessions((prevSelectedSessions) => {
            const newSession = { name, start };

            let isSameSessionClickedAgain = prevSelectedSessions.findIndex((selectedSession) => (selectedSession.name == newSession.name) && (selectedSession.start == newSession.start)) > -1

            if (isSameSessionClickedAgain) {
                return prevSelectedSessions.filter(selectedSession => (selectedSession.name != newSession.name) && (selectedSession.start != newSession.start));
            }

            let isSessionSelectedOnSameDay = prevSelectedSessions.some(session => {
                const currentDate = new Date(session.start);
                const newDate = new Date(newSession.start);

                return (
                    currentDate.getFullYear() === newDate.getFullYear() &&
                    currentDate.getMonth() === newDate.getMonth() &&
                    currentDate.getDate() === newDate.getDate()
                );
            });

            if (!isSessionSelectedOnSameDay) {
                // If no session is selected for the same day, add the new session
                return [...prevSelectedSessions, newSession];
            }

            // If a session is already selected for the same day, replace it with the new session
            return prevSelectedSessions.map((session) => (
                session.start.startsWith(newSession.start.substring(0, 10)) ? newSession : session
            ));
        });
    };

    const mapSessions = (filterByDate) => {

        var filteredSessions = sessions.filter((session) => session.start.startsWith(filterByDate))

        return filteredSessions.map((session, index) => {

            const originalDate = new Date(session.start);
            const formattedDate = new Intl.DateTimeFormat('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }).format(originalDate);

            const startTime = originalDate.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit'
            });

            // Assuming the event lasts for 4.5 hours (from 9:00 to 14:45)
            const endTime = new Date(originalDate.getTime() + (4.5 * 60 * 60 * 1000)).toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit'
            });

            const formattedDateTimeRange = `${formattedDate}, ${startTime} - ${endTime}`;

            const isSessionSelected = selectedSessions.findIndex((selectedSession) => (selectedSession.name == session.name) && (selectedSession.start == session.start)) > -1;

            return (
                <div className={"row pt-3 pb-3 ticket" + (isSessionSelected ? "-selected" : "")} key={index} onClick={() => handleDivClick(session.name, session.start)}>
                    <div className="col-md-1 col-12">
                        {isSessionSelected ? <i className="bi bi-check-square fs-5"></i> : <i className="bi bi-square fs-5"></i>}
                    </div>
                    <div className="col-md-11 col-12 row">
                        <div className="col-md-3 col-12">
                            <p className="fs-6 text-primary mb-2">{session?.name}</p>
                        </div>
                        <div className="col-md-9 col-12 row">
                            <span className="me-4"><i className="bi bi-calendar-week"></i> {formattedDateTimeRange}</span>
                            <span><i className="bi bi-geo-alt-fill"></i> {location.info?.name}, {location.info?.postalCode} {location.info?.city}</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const onSubmit = async () => {
        const order = await addTicketToBasket();
        await addAddressToBasket(order.tickets);
        navigate(`/${language}/address`);
    };

    let disabled = selectedTicketType > 0;

    return (
        <div className="container wrapper">
            <Header exit={true} />
            <div className="p-5">
                <div className="mb-5">
                    <p className="fw-bold mb-2">Anmelden als: <span className="text-danger">*</span></p>
                    <select className="form-select select-anmeldung" value={selectedTicketType} onChange={(e) => setSelectedTicketType(e.target.value)}>
                        <option></option>
                        {
                            ticketTypes.map((tt, index) => (
                                <option value={tt.id} key={index}>
                                    {tt.info?.name}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="mb-4">
                    <p className="fw-bold mb-3">Bitte wählen Sie für jeden Tag eine Session aus <span className="text-danger">*</span></p>
                    <div className="card mb-3">
                        <div className="card-header fs-6">
                            Tag 1 - 15/02/2024
                        </div>
                        {loading ? <div className="p-5"><Loading /></div> : <div className="card-body tickets">
                            {mapSessions("2024-02-15")}
                        </div>}
                    </div>
                    <div className="card">
                        <div className="card-header fs-6">
                            Tag 2 - 16/02/2024
                        </div>
                        {loading ? <div className="p-5"><Loading /></div> : <div className="card-body tickets">
                            {mapSessions("2024-02-16")}
                        </div>}
                    </div>
                </div>
                {error != "" && <div className="alert alert-danger" role="alert">
                    {error}
                </div>}
                <div className="text-end">
                    <button onClick={onSubmit} className="btn button" disabled={!disabled}>Weiter</button>
                </div>
            </div>
        </div>
    )
}

export default Event